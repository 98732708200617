import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Box,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout'; // Import Logout Icon
import axios from 'axios';

const Dashboard = ({ token, handleLogout }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [sortOrder, setSortOrder] = useState('desc'); // State for sorting order

  // Fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://dt-debugger.ctdevelopers.solutions/api/messages/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setMessages(response.data.results);
        setLoading(false);
      } catch (err) {
        setError('Failed to load messages');
        setLoading(false);
      }
    };

    fetchMessages();
  }, [token]);

  // Handle sorting order toggle
  const handleSortOrderToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  // Sort messages by date
  const sortedMessages = [...messages].sort((a, b) => {
    if (sortOrder === 'asc') {
      return new Date(a.created_at) - new Date(b.created_at);
    }
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // Filter messages based on search query
  const filteredMessages = sortedMessages.filter((message) =>
    message.message.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to erase all messages
  const eraseAllMessages = async () => {
    try {
      await axios.delete('https://dt-debugger.ctdevelopers.solutions/api/messages/erase/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setMessages([]); // Clear messages in the UI
    } catch (err) {
      console.error('Failed to erase messages', err);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error" align="center" sx={{ mt: 8 }}>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      {/* Top section: Title, "Erase All Messages" button, and Logout button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mb: 4 }}>
        <Typography variant="h4">Debugger Messages</Typography>

        {/* Button Group */}
        <Box>
          <Button variant="contained" color="error" onClick={eraseAllMessages} sx={{ mr: 2 }}>
            Erase All Messages
          </Button>

          {/* Sort Order Toggle */}
          <Button variant="contained" onClick={handleSortOrderToggle}>
            Sort by Date ({sortOrder === 'asc' ? 'Oldest First' : 'Newest First'})
          </Button>

          {/* Logout Icon Button */}
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Search Bar */}
      <TextField
        label="Search Messages"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 4 }}
      />

      {/* Display All Messages */}
      {filteredMessages.length > 0 ? (
        filteredMessages.map((message, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>Message {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{message.message}</Typography>
              <Typography variant="body2" color="textSecondary">
                Created at: {new Date(message.created_at).toLocaleString()}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          No messages found.
        </Typography>
      )}
    </Container>
  );
};

export default Dashboard;
